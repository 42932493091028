import React, { useState } from 'react';
import '../App.scss';
import paasLogo from '../assets/PAS-Logo-2016-Tx.png';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

function Home({ accountName }) {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    function getOperatingSystem() {
        
        const ua = navigator.userAgent

        if (/android/i.test(ua)) {
            navigate("/google");
        }
        else 
        if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return 2;
        }
        
    };


    async function getPassCard() {
        if (getOperatingSystem() === 2) {
            setIsLoading(true);
            let response = await api.get("/api/applewallet-generate", { headers: { "X-MS-CLIENT-PRINCIPAL-NAME": accountName }, responseType: "blob" });
            if (response.data) {
                let url = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setIsLoading(false);
            }
        }
        else {
            window.alert("Sorry, your Operating System can't be detected.");
            return;
        }

    }

    return (
        <div className="App" onLoad={getOperatingSystem}>
            <div className="welcome">
                <img id="paaslogo" alt="PAAS-Logo" src={paasLogo} />
                <h1>Hi, click on the button to generate your Pan American Silver vCard.</h1>
                {
                    isLoading ?
                        <>
                            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </>
                        :
                        <>
                            <button className="btnGeneratePass" onClick={getPassCard}> Download </button>
                        </>
                }
            </div>
            <div id="userDetail">
                <label>{accountName}</label>
            </div>
        </div>
    );
}

export default Home;

