import React from 'react';
import './App.scss';

// MSAL imports
import AppRoutes from './components/appRoutes';


function App({ msalInst }) {

  return (
      <AppRoutes msalInst={msalInst} />
  );
}

export default App;

