import React, { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import api from '../services/api';
import '../App.scss';
import paasLogo from '../assets/PAS-Logo-2016-Tx.png';
import addToGoogleWallet from '../assets/enCA_add_to_google_wallet_wallet-button.png';

export default function GoogleAccount({ accountName }) {

    const [isLoading, setIsLoading] = useState(false);
    const [addToGoogleWalletUrl, setAddToGoogleWalletUrl] = useState('');

    async function getPassCard() {
        setIsLoading(true);
        let response = await api.get("/api/googlewallet-generate", { headers: { "X-MS-CLIENT-PRINCIPAL-NAME": accountName } });
        if (response.data) {
            setIsLoading(false);
            setAddToGoogleWalletUrl(response.data);
        }
    }

    const errorMessage = (error) => { console.log(error); };

    return (
        <GoogleOAuthProvider clientId="944472837789-mf546jkabatotepd7itiefkcnuselgfs.apps.googleusercontent.com" >
            <div className="App">
                <div className="welcome">
                    <img id="paaslogo" alt="PAAS-Logo" src={paasLogo} />
                    <h1>Hi, click on the button to login with your Google account and generate your PanAmerican Silver vCard.</h1>
                    {
                        isLoading ?
                            <>
                                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </>
                            :
                            <div className="google-buttons">
                                { addToGoogleWalletUrl === '' ? <GoogleLogin onSuccess={getPassCard} onError={errorMessage} /> : null }
                                { addToGoogleWalletUrl !== '' ? <a className="addToGoogleWallet" href={addToGoogleWalletUrl} > <img alt="Save to Google Wallet" src={addToGoogleWallet} /> </a> : null }
                            </div>
                    }
                </div>
                <div id="userDetail">
                    <label>{accountName}</label>
                </div>
            </div>
        </GoogleOAuthProvider>

    )
}