import axios from "axios";

const api = axios.create({
    baseURL : "https://paas-wallet.azurewebsites.net/"
});

api.interceptors.request.use(async config => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['x-functions-key'] = '5ZaSgMWqRink3l3eeI7Bp_9zStfVezZs_gGbBDyXEjd0AzFudRuj4g==';
    config.withCredentials = false;
    return config;
});

export default api;