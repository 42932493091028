import { Route, Routes, BrowserRouter } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import Home from "../pages/home";
import GoogleAccount from "../pages/googleAccount";

const AppRoutes = ({msalInst}) => {

    const [accountName, setAccountName] = useState();

    useEffect(() => {

        // handle auth redired/do all initial setup for msal
        msalInst.handleRedirectPromise().then(authResult => {
    
          // Check if user signed in 
          const account = msalInst.getActiveAccount();
          if (!account) {
            // redirect anonymous user to login page 
            msalInst.loginRedirect();
          }
          else {
            setAccountName(account.username);
          }
        }).catch(err => {
          // TODO: Handle errors
          console.log(err);
        });
    
      }, [msalInst]);



    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Home accountName={accountName}/>} path="/" exact />
                <Route element={<GoogleAccount accountName={accountName} />} path="/google" exact/>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;